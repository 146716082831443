import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('h1',{staticClass:"heading"},[_vm._v("Banner statistics")]),_c('ObjectFilter',{attrs:{"object_id":_vm.$store.state.statistics.object_id,"objects":_vm.objects.list},on:{"changeObject":_vm.changeObject,"changeDates":_vm.changeDates,"reloadData":_vm.reloadData}})],1),_c(VTabs,[_c(VTab,[_vm._v(" Intro ")]),_c(VTab,[_vm._v(" Modal ")]),_c(VTab,[_vm._v(" Bottom ")]),_c(VTabItem,[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"statistics__statistics",style:(("width:" + (_vm.isMobile?'100%':'calc(66.66% + 20px)') + ";"))},[_c(VAutocomplete,{staticClass:"statistics__type",style:(("width:" + (_vm.isMobile?'150px':'320px') + ";")),attrs:{"solo":"","flat":"","dense":"","outlined":"","placeholder":_vm.$t('statistics[\'Открытия\']'),"items":Object.keys(_vm.introChart).map(function (key) { return ({ status: key, name: key }); }),"item-value":"status","item-text":"name"},on:{"change":_vm.changeIntroStatus},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkIntroName(item.status))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkIntroName(item.status))+" ")]}}]),model:{value:(_vm.current_banner_intro),callback:function ($$v) {_vm.current_banner_intro=$$v},expression:"current_banner_intro"}}),_c('div',{staticClass:"statistics__chart"},[_c('Chart',{attrs:{"data":_vm.introChartData,"labels":_vm.dateArr,"pointRadius":4,"xAxes_ticks":true}})],1)],1),(!_vm.isMobile)?_c('div',{staticClass:"statistics__info"},[_c('h3',{staticClass:"statictics__caption"},[_vm._v(" Number of shows ")]),_c('p',{staticClass:"statistics__period"},[_c('span',[_vm._v(_vm._s(_vm.totalIntro))]),_vm._v(" "+_vm._s(_vm.$t('statistics["за текущий период"]'))+" ")]),_c('p',{staticClass:"statistics__total"},[_c('span',[_vm._v("The graph shows the number of intro show in your mobile application: "+_vm._s(_vm.hotelName))])])]):_vm._e()]),_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c(VDataTable,{staticClass:"table",attrs:{"headers":_vm.introStatisticsTableHeaders,"items":_vm.introTableItems,"hide-default-footer":"","items-per-page":999},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length)?_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(" "+_vm._s(item.date)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.value)+" ")])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center","padding":"0 15px","font-weight":"normal"},attrs:{"colspan":"3"}},[_vm._v(" Sorry no data for you ")])])])]}}])})],1)]),_c(VTabItem,[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"statistics__statistics",style:(("width:" + (_vm.isMobile?'100%':'calc(66.66% + 20px)') + ";"))},[_c(VAutocomplete,{staticClass:"statistics__type",style:(("width:" + (_vm.isMobile?'150px':'320px') + ";")),attrs:{"solo":"","flat":"","dense":"","outlined":"","placeholder":_vm.$t('statistics[\'Открытия\']'),"items":_vm.events,"item-value":"status","item-text":"name"},on:{"change":_vm.changeEventModal},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}}]),model:{value:(_vm.current_event_modal),callback:function ($$v) {_vm.current_event_modal=$$v},expression:"current_event_modal"}}),_c(VAutocomplete,{staticClass:"statistics__type",style:(("width:" + (_vm.isMobile?'150px':'320px') + ";left:" + (_vm.isMobile?'250px':'400px') + ";")),attrs:{"solo":"","flat":"","dense":"","outlined":"","placeholder":_vm.$t('statistics[\'Открытия\']'),"items":Object.keys(_vm.modalChart[_vm.eventStatuses[_vm.current_event_modal]]).map(function (key) { return ({ status: key, name: key }); }),"item-value":"status","item-text":"name"},on:{"change":_vm.changeModalBanner},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkModalName(item.status))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkModalName(item.status))+" ")]}}]),model:{value:(_vm.current_banner_modal),callback:function ($$v) {_vm.current_banner_modal=$$v},expression:"current_banner_modal"}}),_c('div',{staticClass:"statistics__chart"},[_c('Chart',{attrs:{"data":_vm.modalChart[_vm.eventStatuses[_vm.current_event_modal]][_vm.current_banner_modal],"labels":_vm.dateArr,"pointRadius":4,"xAxes_ticks":true}})],1)],1),(!_vm.isMobile)?_c('div',{staticClass:"statistics__info"},[_c('h3',{staticClass:"statictics__caption"},[_vm._v(" Number of shows ")]),_c('p',{staticClass:"statistics__period"},[_c('span',[_vm._v(_vm._s(_vm.totalModal))]),_vm._v(" "+_vm._s(_vm.$t('statistics["за текущий период"]'))+" ")]),_c('p',{staticClass:"statistics__total"},[_c('span',[_vm._v("The graph shows the number of modal banner "+_vm._s(_vm.current_event_modal.toLowerCase())+" in your mobile application: "+_vm._s(_vm.hotelName))])])]):_vm._e()]),_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c(VDataTable,{staticClass:"table",attrs:{"headers":_vm.introStatisticsTableHeaders,"items":_vm.modalTableItems,"hide-default-footer":"","items-per-page":999},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length)?_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(" "+_vm._s(item.date)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.value)+" ")])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center","padding":"0 15px","font-weight":"normal"},attrs:{"colspan":"3"}},[_vm._v(" Sorry no data for you ")])])])]}}])})],1)]),_c(VTabItem,[_c('div',{staticClass:"statistics"},[_c('div',{staticClass:"statistics__statistics",style:(("width:" + (_vm.isMobile?'100%':'calc(66.66% + 20px)') + ";"))},[_c(VAutocomplete,{staticClass:"statistics__type",style:(("width:" + (_vm.isMobile?'150px':'320px') + ";")),attrs:{"solo":"","flat":"","dense":"","outlined":"","placeholder":_vm.$t('statistics[\'Открытия\']'),"items":_vm.events,"item-value":"status","item-text":"name"},on:{"change":_vm.changeEventBottom},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item)+" ")]}}]),model:{value:(_vm.current_event_bottom),callback:function ($$v) {_vm.current_event_bottom=$$v},expression:"current_event_bottom"}}),_c(VAutocomplete,{staticClass:"statistics__type",style:(("width:" + (_vm.isMobile?'150px':'320px') + ";left:" + (_vm.isMobile?'250px':'400px') + ";")),attrs:{"solo":"","flat":"","dense":"","outlined":"","placeholder":_vm.$t('statistics[\'Открытия\']'),"items":Object.keys(_vm.bottomChart[_vm.eventStatuses[_vm.current_event_bottom]]).map(function (key) { return ({ status: key, name: key }); }),"item-value":"status","item-text":"name"},on:{"change":_vm.changeBottomBanner},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkBottomName(item.status))+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.checkBottomName(item.status))+" ")]}}]),model:{value:(_vm.current_banner_bottom),callback:function ($$v) {_vm.current_banner_bottom=$$v},expression:"current_banner_bottom"}}),_c('div',{staticClass:"statistics__chart"},[_c('Chart',{attrs:{"data":_vm.bottomChart[_vm.eventStatuses[_vm.current_event_bottom]][_vm.current_banner_bottom],"labels":_vm.dateArr,"pointRadius":4,"xAxes_ticks":true}})],1)],1),(!_vm.isMobile)?_c('div',{staticClass:"statistics__info"},[_c('h3',{staticClass:"statictics__caption"},[_vm._v(" Number of shows ")]),_c('p',{staticClass:"statistics__period"},[_c('span',[_vm._v(_vm._s(_vm.totalBottom))]),_vm._v(" "+_vm._s(_vm.$t('statistics["за текущий период"]'))+" ")]),_c('p',{staticClass:"statistics__total"},[_c('span',[_vm._v("The graph shows the number of bottom banner "+_vm._s(_vm.current_event_bottom.toLowerCase())+" in your mobile application: "+_vm._s(_vm.hotelName))])])]):_vm._e()]),_c('div',{staticStyle:{"margin-bottom":"15px"}},[_c(VDataTable,{staticClass:"table",attrs:{"headers":_vm.introStatisticsTableHeaders,"items":_vm.bottomTableItems,"hide-default-footer":"","items-per-page":999},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [(items.length)?_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',[_vm._v(" "+_vm._s(item.date)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.value)+" ")])])}),0):_c('tbody',[_c('tr',[_c('td',{staticClass:"d-block d-sm-table-cell",staticStyle:{"text-align":"center","padding":"0 15px","font-weight":"normal"},attrs:{"colspan":"3"}},[_vm._v(" Sorry no data for you ")])])])]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }